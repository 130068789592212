import React from 'react';
import { Button, Card, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const MetricCard = styled(Card)(({ theme, color }) => ({
    minWidth: "150px",
    maxWidth: "150px",
    height: "65px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignContent: "flex-start",
    textAlign: "left",
    padding: theme.spacing(2),
    justifySelf: "center",
}));

function Number(
    {
        number, description
    }
) {
    return (
        <MetricCard
            variant="outlined">
            <Typography justifySelf="left" alignSelf="left" variant="h5">{number}</Typography>
            <Typography alignSelf="left" variant="subtitle2">{description}</Typography>
        </MetricCard>
        // </Card>
    );
}

export default Number;