import React from 'react';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material'


function Login() {
    const navigate = useNavigate()
    const navigateHome = (response) => {
        console.log("Login response:")
        console.log(JSON.stringify(response));
        sessionStorage.setItem('user', JSON.stringify(response))
        navigate('/')
    };
    const errorMessage = (error) => {
        console.log(error);
        navigate('/error')
    };
    const login = useGoogleLogin({
        onSuccess: navigateHome,
        onError: errorMessage
    })

    return (
        <div>
            <Box 
            textAlign="center"
            >
            <h2>Aware Health Metrics Dashboard</h2>
                <Button
                    variant="outlined"
                    onClick={login}>
                    Log in
                </Button>
                {/* <GoogleLogin
                    onSuccess={navigateHome}
                    onError={errorMessage}
                    shape="rectangular"
                    size="large"
                    text="signin_with"
                    width="360"
                    type="standard"
                    theme="outline"
                    logo_alignment="left" /> */}
            </Box>
        </div>
    )
}

export default Login;