import React from "react";
import Number from "./Number";
import { Box, Typography, Card, styled } from "@mui/material"
import { BarChart } from "@mui/x-charts";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// Title of metric report used by all charts and tables
const MetricName = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}))

const LargeMetricCard = styled(Card)(({ theme, color }) => ({
    minWidth: '200px',
    width: '95%',
    display: 'flex',
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    textAlign: "left",
    padding: theme.spacing(2),
}));

const FlatTableContainer = styled(TableContainer)(({ theme }) => ({
    boxShadow: 'none',
    border: '1px solid #e0e0e0',
}))

const BoldTableCell = styled(TableCell)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}))

const TableBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    textAlign: 'left',
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableHeading = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover,
}))

export function TableMetric({ data, headings, description }) {
    return (
        <TableBox >
            <LargeMetricCard variant="outlined">
                <MetricName variant="h5">{description}</MetricName>
                <FlatTableContainer component={Paper}>
                    <Table aria-label="simple table" width='100%'>
                        <TableHead>
                            <StyledTableHeading>
                                {headings?.map((heading, index) => (
                                    <BoldTableCell key={`${description}-${heading}-${index}`} align="left">{heading}</BoldTableCell>
                                ))}
                            </StyledTableHeading>
                        </TableHead>
                        <TableBody>
                            {data.data && data.data.map((row, index) => {
                                return (
                                    <StyledTableRow
                                        key={`${description}-table_row-${index}`}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {row.values?.map((value, index) => {
                                            return (
                                                <TableCell key={`tablecell-${index}`}>
                                                    {value}
                                                </TableCell>
                                            )
                                        })}
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </FlatTableContainer>
            </LargeMetricCard>
        </TableBox>
    )
}


export function NumberMetrics({ metricName, metricValue }) {
    if (typeof metricValue === 'number' || typeof metricValue === 'string') {
        return (
            <Number number={metricValue} description={metricName} />
        )
    }

    return null
}

export function BarChartMetric({ data, description }) {
    return (
        <Box>
            <LargeMetricCard variant="outlined" >
                <MetricName variant="h5" alignSelf='left' justifySelf='left'>{description}</MetricName>
                <BarChart
                    xAxis={[{
                        scaleType: 'band', data: data.xAxis
                    }]}
                    series={[{
                        data: data.yAxis
                    }]}
                    height={300}
                />
            </LargeMetricCard>
        </Box>

    )
}

const FlexItem = styled(Box)(({ theme }) => ({
    flexBasis: { xs: '100%', sm: '50%', md: '50%' },
    maxWidth: { xs: '100%', sm: '45%', md: '45%' },
    alignSelf: 'flex-start',
    margin: theme.spacing(1),
}));
const FullWidthDivider = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))

export function NumberDashboard({ data }) {
    return (
        <>
            {data && Object.values(data).map((metric, index) => {
                return (
                    <FlexItem key={`number-metric-${index}`} >
                        <NumberMetrics metricName={metric.name} metricValue={metric.value} />
                    </FlexItem>
                )
            })}
            <FullWidthDivider />
        </>
    )
}

export function SeriesDashboard({ seriesData, metricCharts, metricTables }) {
    return (
        Object.entries(seriesData).map(([key, data], index) => {
            return (
                <FlexItem
                    key={`series-${key}`}
                    sx={{
                        flexBasis: { xs: '100%', sm: '50%', md: '50%' },
                        maxWidth: { xs: '100%', sm: '45%', md: '45%' },
                    }}
                >
                    {metricCharts.has(key) && <BarChartMetric data={data} description={data.name} />}
                    {metricTables.has(key) && <TableMetric headings={data.headings} data={data} description={data.name} />}
                </FlexItem>
            )
        })
    )
}