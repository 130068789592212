import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

export default function ButtonAppBar() {
    const navigate = useNavigate()
    const handleLogout = () => {
        sessionStorage.removeItem('user')
        navigate('/login')
    }
    return (
        <AppBar position="static">
            <Toolbar sx={{ justifyContent: "flex-end" }}>
                <Button color="inherit" onClick={ handleLogout }>Log out</Button>
            </Toolbar>
        </AppBar>
    );
}
